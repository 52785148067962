import { createRouter, createWebHashHistory, createWebHistory } from "vue-router";
 
// 1. 配置路由
const routes = [
  {
    path: "/", // 默认路由 home页面
   name:"home",
    component: () => import("../views/home/index.vue"),
  },
  {
    path: "/douyin", // 默认路由 home页面
    name:"douyin",
    component: () => import("../views/douyin/index.vue"),
  },
  {
    path: "/video", // 默认路由 home页面
    name:"video",
    component: () => import("../views/video/index.vue"),
  },
  {
    path: "/dayin", // 默认路由 home页面
    name:"dayin",
    component: () => import("../views/dayin/index.vue"),
    beforeEnter:(to, from, next) => {
      // 在路由进入前清除历史记录
      next(vm => {
        // 使用 router.replace 确保没有历史记录
        router.replace({ path: '/dayin', force: true });
        // 清除当前页面的历史记录
        window.history.pushState(null, null, document.URL);
      });
  }
}
];
// 2.返回一个 router 实列，为函数，配置 history 模式
const router = createRouter({
  mode: 'hash',
  history: createWebHashHistory(),
  routes,
});
 
// 3.导出路由   去 main.ts 注册 router.ts
export default router