import { createApp } from 'vue'
import router from './router'

import App from './App.vue'

import vue3videoPlay from "vue3-video-play"; // 引入组件
import "vue3-video-play/dist/style.css"; // 引入css

import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';


import { Swipe, SwipeItem } from 'vant';
import { Toast } from 'vant'




router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.redirect)) {
        next();
    } else {
        const isSSL = window.location.protocol === 'https:';
        if (!isSSL && process.env.VUE_APP_FORCE_HTTPS === 'true') {
            const newUrl = to.fullPath;
            window.location.href = `https://${window.location.host}${newUrl}`;
        } else {
            next();
        }
    }
});

createApp(App).use(ElementPlus).use(Swipe)
    .use(SwipeItem).use(Toast).use(vue3videoPlay)
    .use(router).mount('#app')
